<template>

  <!-- Publicists Directory View -->
  <j-page
    :title="pageTitle"
    :subtitle="pageSubtitle"
    :loading="loading"
    fill
    id="staff-directory-view"
  >

    <!-- Actions -->
    <template #actions>

      <!-- Search Bar -->
      <j-search
        :query="query.q"
        :open="searchMode"
        @expand="handleExpand"
        @search="handleSearch"
        @clear="handleClear"
      />

      <!-- Add Publicist Button -->
      <j-button
        type="icon"
        help="Add a publicist to the directory"
        icon="plus"
        @action="handleAddPublicist"
      />

    </template>

    <!-- Main Column -->
    <v-col>

      <!-- Table -->
      <PublicistsTable
        :heading="heading"
        :publicists="pageListing"
        @paginate="handlePaginate"
        @edit="handleEditPublicist"
      />

    </v-col>

    <!-- Dialogs -->
    <template #dialogs>

      <!-- Add / Edit Publicist -->
      <PublicistFormDialog
        v-if="$dialog.status('publicist-form')"
        @refresh="fetchPublicists"
      />

    </template>  
  </j-page>
</template>

<script>

// Components
import PublicistFormDialog from '@/components/Publicists/PublicistFormDialog'
import PublicistsTable from '@/components/Publicists/PublicistsTable'

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapGetters, mapActions, mapMutations } = createNamespacedHelpers('publicists')

export default {
  name: 'PublicistsDirectoryView',
  // -------------
  // Components ==
  // -------------
  components: {
    PublicistFormDialog,
    PublicistsTable
  },
  // -------------
  // Middleware ==
  // -------------
  beforeRouteEnter(to, _from, next) {
    next(vm => {
      if (vm.searchMode) {
        vm.fetchQuery({
          p: to.query.p,
          q: to.query.q
        })
      } else {
        vm.$store.dispatch('publicists/fetchPublicists')
      }
    })
  },
  beforeRouteUpdate (to, _from, next) {
    if (this.searchMode) {
      this.fetchQuery({
        p: to.query.p,
        q: to.query.q
      })
    }
    next()
  },
  // -----------
  // Metadata ==
  // -----------
  metaInfo() {
    return {
      title: this.searchMode ? `Publicists [matching ${this.query.q}]` : 'Publicists Directory'
    }
  },
  data() {
    return {
      mode: 'directory'      
    }
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    // Vuex
    ...mapState([
      'publicists',
      'query'
    ]),
    ...mapGetters([
      'loading'
    ]),
    // UI
    pageTitle () {
      return this.searchMode ? 'Publicists' : 'Publicists Directory'
    },
    pageSubtitle () {
      return this.searchMode && this.query.q ? `matching &laquo;${this.query.q}&raquo;` : ''
    },
    pageListing () {
      return this.searchMode ? this.query : this.publicists
    },
    directoryMode () { return this.mode === 'directory' },
    searchMode () { return this.mode === 'search' },
    heading () {
      if (this.query.q) {
        return this.query.loading
          ? 'Showing ...'
          : `Showing ${this.query.get('start')} - ${this.query.get('end')} of ${this.query.get('count')}`
      } else {
        return 'Enter a search term...'
      }
    },
    // Helpers
    pageQuery () {
      return {
        p: this.query.p,
        q: this.query.q
      }
    }
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    // Vuex
    ...mapActions([
      'fetchPublicists',
      'fetchQuery'
    ]),
    ...mapMutations([
      'NEW_PUBLICIST',
      'SET_PUBLICIST',
      'NEW_QUERY'
    ]),
    // Handlers
    handleExpand () {
      this.NEW_QUERY('publicists')
      this.mode = 'search'
    },
    handleSearch (query) {
      this.query.q = query
      this.query.p = 1
      this.fetchQuery(this.pageQuery)
    },
    handleClear () {
      this.mode = 'directory'
    },
    handlePaginate (page) {
      this.query.p = page
      this.fetchPublicists(page)
    },
    handleEditPublicist (publicist) {
      this.SET_PUBLICIST(publicist)
      console.log('SET_PUBLICIST', publicist)
      this.$dialog.open('publicist-form')
    },
    handleAddPublicist () {
      this.NEW_PUBLICIST({ newsletter: true })
      this.$dialog.open('publicist-form')
    }
  }
}
</script>

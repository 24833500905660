<template>
  <j-table
    class="publicists-table"
    :listing="publicists"
    items="publicists"
    :heading="heading"
    @paginate="handlePaginate"
  >
    <!-- Actions -->
    <template #actions>
      <slot name="actions" />
    </template>

    <!-- Header -->
    <template #header>
      <tr>
        <th>Name</th>
        <th>Organization</th>
        <th>Email</th>
        <th>Latest</th>
        <th>Last Updated</th>
        <th>&nbsp;</th>
      </tr>
    </template>

    <!-- Listing -->
    <template #listing>
      <tr
        v-for="publicist in publicists.getModels()"
        :key="publicist.slug"
      >
        <!-- Full Name -->
        <td>
          <strong><router-link :to="{ name: 'publicist-view', params: { slug: publicist.slug }}">
            {{ publicist.name || publicist.email }}
          </router-link></strong>
        </td>

        <!-- Organization -->
        <td v-html="publicist.organization" />

        <td>
          <a :href="`mailto:${publicist.email}`">
            <j-icon
              icon="envelope"
              :title="publicist.email"
            />
          </a>
        </td>

        <!-- Latest Item -->
        <td> 
          <router-link
            v-if="publicist.latest_item.title"
            :to="{ name: 'articles-view', params: { tag19: publicist.latest_item.tag19 }}"
          >
            {{ publicist.latest_item.title | truncate(40) }}
          </router-link>
        </td>

        <!-- Updated At -->
        <td>
          {{ publicist.updated_at | shortDate }}
        </td>

        <!-- Admin Actions -->
        <td> 
          <j-button
            icon="edit"
            type="icon"
            :help="`Edit ${ publicist.name || publicist.email }`"
            @action="handleEdit(publicist)"
          />
        </td>
      </tr>
    </template>
  </j-table>
</template>

<script>

export default {
  name: 'PublicistsTable',
  // -------------
  // Properties ==
  // -------------
  props: {
    heading: {
      type: String,
      default: ''
    },
    publicists: {
      type: Object,
      default: null
    },
    hide: {
      type: Array,
      default: () => []
    }
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    show(column) {
      if (this.hide) {
        return !this.hide.includes(column)
      } else {
        return true
      }
    },
    handleEdit(publicist) {
      this.$emit('edit', publicist)
    },
    handlePaginate(page) {
      this.$emit('paginate', page)
    }
  }
}
</script>

<template>

  <!-- Publicist Form Dialog  -->
  <j-dialog
    dialog-name="publicist-form"
    :value="$dialog.status('publicist-form')"
    :title="formTitle"
    max-width="50%"
    @cancel="handleCloseDialog"
  >
    <j-card-text>
      <!-- Success -->
      <j-alert
        v-if="showMessage"
        type="success"
        message="The publicist record has been created."
        class="mt-4"
      />

      <j-form v-else :model="publicist">

        <!-- First Name -->
        <j-control
          name="first_name"
          cols="12"
          sm="6"
        />

        <!-- Last Name -->
        <j-control
          name="last_name"
          cols="12"
          sm="6"
        />

        <!-- Email -->
        <j-control
          name="email"
          cols="12"
          sm="6"
        />

        <!-- Organization -->
        <j-control
          name="organization"
          cols="12"
          sm="6"
        />

        <!-- Contact Notes -->
        <j-control name="contact_notes" />

        <!-- Newsletter -->
        <j-control
          name="newsletter"
          cols="12"
          sm="6"
        />

      </j-form>
    </j-card-text>

    <!-- Actions -->
    <template #actions>

      <!-- Save -->
      <j-button
        v-if="!showMessage"
        :label="buttonLabel"
        :disabled="publicist.pristine"
        :processing="publicist.saving"
        @action="handleSavePublicist"
      />

      <!-- Close -->
      <j-button
        v-else
        label="Close"
        @action="handleCloseDialog"
      />
    </template>

    <!-- Options -->
    <template #options>

      <!-- Cancel -->
      <j-button
        v-if="!(publicist.saving || showMessage)"
        label="Close"
        type="option"
        @action="handleCloseDialog"
      />

    </template>
  </j-dialog>
</template>

<script>

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions } = createNamespacedHelpers('publicists')

export default {
  name: 'PublicistFormDialog',
  // -------
  // Data ==
  // -------
  data () {
    return {
      showMessage: false
    }
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    ...mapState([
      'publicist'
    ]),
    formTitle () {
      return this.publicist.isNew() ? 'Add Publicist' : 'Edit Publicist'
    },
    buttonLabel () {
      return this.publicist.isNew() ? 'Create' : 'Save'
    }
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    ...mapActions([
      'savePublicist',
    ]),
    handleCloseDialog () {
      this.showMessage = false
      this.$dialog.close('publicist-form')
    },
    handleSavePublicist() {
      const isNew = this.publicist.isNew()
      this.savePublicist().then(() => {
        if (isNew) {
          this.$emit('refresh')
          this.showMessage = this.publicist.isValid
        } else {
          this.showMessage = false
          this.$dialog.close('publicist-form')
        }
      })
    }
  }
}
</script>
